import React from 'react'
import { Link } from 'gatsby'
// Components
import { PageHeader, Seo } from '../components'
// Styles
import { Container, StyledSpan, Wrapper } from '../styles/common'
// Context
import { usePrismic } from '../context/PrismicContext'



const hemp_info = (location) => {
    const {
        prismicData: {
            prismicSciencePage: { hemp_prod_certs },
          },
        } = usePrismic()

    const returnUrl = location?.state?.returnUrl
  return (
    <>
    <Seo title="hemp-info" />
    <PageHeader exitRoute = {returnUrl} >{hemp_prod_certs[0].text}</PageHeader>
    <Wrapper>
      <Container style={{ flex: 1 }}>
        <h1>Q Fuse Cinnamon</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Cinnamon+QSCI-001.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Cinnamon+QSCI-001.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Cinnamon+QSCI-002.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Cinnamon+QSCI-002.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Cinnamon+QSCI-003.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Cinnamon+QSCI-003.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q Fuse Cinnamon THC Free (Includes Japan)</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/QJCI-001+Q-Sciences+Cinnamon+Japan+Botanacor+COA+(1).pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/QJCI-001+Q-Sciences+Cinnamon+Japan+Botanacor+COA+(1).pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q FUSE Berry</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Berry+102S.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Berry+102S.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Berry+QSBE-002.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Berry+QSBE-002.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Berry+QSBE-003.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Berry+QSBE-003.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q FUSE Berry THC Free (Includes Japan)</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Berry+THC+Free+QJBE-001.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Berry+THC+Free+QJBE-001.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q FUSE Original (Includes Japan)</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/QSNA-001+Q-Sciences+Natural+Botnacor+COA+(1).pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/QSNA-001+Q-Sciences+Natural+Botnacor+COA+(1).pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q REST +</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Rest%2B+QSRE-001.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Rest%2B+QSRE-001.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Rest%2B+QSRE-002.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Rest%2B+QSRE-002.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q FUSE Innovate</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Innovate+QSFI-001.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Fuse+Innovate+QSFI-001.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q PETS</h1>
        <Link target='blank' to="https://producttests.s3-us-west-2.amazonaws.com/QSNA-001+Q+PETS+COA.pdf">
            <StyledSpan>https://producttests.s3-us-west-2.amazonaws.com/QSNA-001+Q+PETS+COA.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+PETS+QSNA-002.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+PETS+QSNA-002.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q RENEW Intensive Roll-On</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/RENEWRollon+COA+3.2.21+QS-RNRO-21-A.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/RENEWRollon+COA+3.2.21+QS-RNRO-21-A.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q RELAX Intensive Roll-On</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q%2BRELAX%2BRoll%2Bon%2BCOA%2B3.2.21%2BQS-RXRO-21-A.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q%2BRELAX%2BRoll%2Bon%2BCOA%2B3.2.21%2BQS-RXRO-21-A.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q DEFEND Intensive Roll-On</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q%2BDEFEND%2BRoll%2Bon%2BCOA%2B3.2.21%2BQS-DERO-21-A.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q%2BDEFEND%2BRoll%2Bon%2BCOA%2B3.2.21%2BQS-DERO-21-A.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q TWIST CRAVE Berry Smoothie</h1>
        <Link target='blank' to="https://producttests.s3-us-west-2.amazonaws.com/QTwist+Berry+APRC+FY+6A86E+(21D-1).pdf">
            <StyledSpan>https://producttests.s3-us-west-2.amazonaws.com/QTwist+Berry+APRC+FY+6A86E+(21D-1).pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Crave+Berry+Smoothie+-+Lot%23+211276408.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Crave+Berry+Smoothie+-+Lot%23+211276408.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Crave+Berry+Smoothie+-+Lot%23+212536353+(212360004).pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Crave+Berry+Smoothie+-+Lot%23+212536353+(212360004).pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q TWIST CRAVE Raspberry Lime</h1>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Crave+Raspberry+Lime+-+Lot%23+211026405.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Crave+Raspberry+Lime+-+Lot%23+211026405.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Crave+Raspberry+Lime+-+Lot%23+212096405.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Crave+Raspberry+Lime+-+Lot%23+212096405.pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q TWIST HYDRATE Juicy Grape</h1>
        <Link target='blank' to="https://producttests.s3-us-west-2.amazonaws.com/QScience+Grape+APRC+FY+D1122+(29A).pdf">
            <StyledSpan>https://producttests.s3-us-west-2.amazonaws.com/QScience+Grape+APRC+FY+D1122+(29A).pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Hydrate+Juicy+Grape+-+Lot%23+212096409.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Hydrate+Juicy+Grape+-+Lot%23+212096409.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Hydrate+Juicy+Grape+-+Lot%23+212536353+(212090005).pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Hydrate+Juicy+Grape+-+Lot%23+212536353+(212090005).pdf</StyledSpan>
        </Link>
        <br />
        <h1>Q TWIST CALM Peach Lemonade</h1>
        <Link target='blank' to="https://producttests.s3-us-west-2.amazonaws.com/QScience+Peach+Lemonade+APRC+FY+CE98A+(29B).pdf">
            <StyledSpan>https://producttests.s3-us-west-2.amazonaws.com/QScience+Peach+Lemonade+APRC+FY+CE98A+(29B).pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Calm+Peach+Lemonade+-+Lot%23+211276403.pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Calm+Peach+Lemonade+-+Lot%23+211276403.pdf</StyledSpan>
        </Link>
        <Link target='blank' to="https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Calm+Peach+Lemonade+-+Lot%23+212536353+(212430006).pdf">
            <StyledSpan>https://producttests.s3.us-west-2.amazonaws.com/Q+Twist+Calm+Peach+Lemonade+-+Lot%23+212536353+(212430006).pdf</StyledSpan>
        </Link>
      </Container>
    </Wrapper>
  </>
  )
}

export default hemp_info